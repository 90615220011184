import React, { useState } from 'react';
import {useHistory} from 'react-router-dom'
import {Form, Icon, Input, Button, Row, Col, notification} from 'antd';
import {Typography} from 'antd';


import { LOGIN_URL } from '../../config'
import {publicApi} from '../../api'
import AuthClientStore from '../../api/authClient'

function Auth(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const openNotificationWithIcon = (type, text) => {
	notification[type]({
		message: 'Ошибка',
		description:
		text,
	});
  };

  const history = useHistory();
  const login = async () => {
    try {
        const response = await publicApi.post(LOGIN_URL, { username, password });
		AuthClientStore.setAccessToken(response.data.accessToken)
		AuthClientStore.setRefreshToken(response.data.refreshToken)
        const path = '/consignment';
        history.push(path)
    } catch (error) {
        if (error.message === 'Request failed with status code 401') {
            let errMsg = 'Неправильный логин или пароль.'
            openNotificationWithIcon('error', errMsg)
        } else {
            let errMsg = 'Что-то пошло не так. Попробуйте повторить запрос позднее.'
            openNotificationWithIcon('error', errMsg)
        }
    }
  };

  const {getFieldDecorator} = props.form;

  const {Title} = Typography;

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
        if (!err) {
            login()
        }
    })
}

  return (
    <div>
				<Row type="flex" justify="center">
					<Title style={{marginTop: 30}}>Добро пожаловать на портал экспедиторов</Title>
				</Row>
				<Row type="flex" justify="center">
					<Col xs={24} sm={20} md={12} lg={8} xl={6} style={{marginTop: 50, padding: 15}}>
						<Form onSubmit={handleSubmit} className="login-form">
							<Form.Item>
								{getFieldDecorator('username', {
									rules: [{required: true, message: 'Введите имя пользователя!'}],
								})(
									<Input
										prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
										placeholder="Логин"
                                        onChange={e => setUsername(e.target.value)}
									/>,
								)}
							</Form.Item>
							<Form.Item>
								{getFieldDecorator('password', {
									rules: [{required: true, message: 'Введите пароль!'}],
								})(
									<Input
										prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
										type="password"
										placeholder="Пароль"
                                        onChange={e => setPassword(e.target.value)}
									/>,
								)}
							</Form.Item>
							<Form.Item type="flex" justify="center">
								<Row type="flex" justify="center">
									<Button type="primary" htmlType="submit" className="login-form-button">
										Войти
									</Button>
								</Row>
							</Form.Item>
						</Form>
					</Col>

				</Row>
    </div>
  );
}

export default Form.create()(Auth)