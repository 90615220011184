import React from 'react';
import logo from '../../logo.png';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import {
	Row,
	Col,
	Icon,
} from 'antd';
import {NavLink} from "react-router-dom";
import {Typography} from 'antd';
import {Layout, Menu, Breadcrumb} from 'antd';
import Profile from "../Profile/Profile";
import Cookies from "js-cookie";
import { CONSIGNMENTS_HISTORY_URL } from '../../config'
import { privateApi } from '../../api'


const {Content, Footer, Sider} = Layout;
const {Title} = Typography;
let user
Cookies.get("username")
if (Cookies.get("username")) {
//	user = JSON.parse(Cookies.get("username")).username
}
let today = new Date();
let dd = today.getDate();
let mm = today.getMonth() + 1; //January is 0!

let yyyy = today.getFullYear();
if (dd < 10) {
	dd = '0' + dd;
}
if (mm < 10) {
	mm = '0' + mm;
}

function getLinkName(link) {
    let name = ''
    switch (link) {
        case 'delivCustomEst':
            name = 'Отправка транспортного средства от поставщика - прогноз';
            break;
        case 'delivCustomFact':
            name = 'Отправка транспортного средства от поставщика - факт';
            break;
        case 'delivPortEst':
            name = 'Доставка партии в порт - прогноз';
            break;
        case 'delivPortFact':
            name = 'Доставка партии в порт - факт';
            break;
        case 'sendPartTOEst':
            name = 'Отправка партии из порта на ТО - прогноз';
            break;
        case 'sendPartTOFact':
            name = 'Отправка партии из порта на ТО - факт';
            break;
        case 'delivCustomTerminalEst':
            name = 'Доставка партии на таможенный терминал РФ - прогноз';
            break;
        case 'delivCustomTerminalFact':
            name = 'Доставка партии на таможенный терминал РФ - факт';
            break;
        case 'startToFactFact':
            name = 'Начало ТО партии - факт';
            break;
        case 'endToFactFact':
            name = 'Окончание ТО партии - факт';
            break;
        case 'sendDomesticEst':
            name = 'Отправка партии по России - прогноз';
            break;
        case 'sendDomesticFact':
            name = 'Отправка партии по России - факт';
            break;
        case 'PartyArrivalEst':
            name = 'Прибытие партии на склад иэк – прогноз экспертный';
            break;
        case 'delivCustomFactChangeReason':
            name = 'Причина изменения даты/Комментарий на звене (Отправка транспортного средства от поставщика)';
            break;
        case 'delivCustomTerminalFactChangeReason':
            name = 'Причина изменения даты/Комментарий на звене (Доставка партии на таможенный терминал РФ)';
            break;
        case 'sendDomesticFactChangeReason':
            name = 'Причина изменения даты/Комментарий на звене (Отправка партии по России)';
            break;
        default:
            name = '';
    }
    return name
}

function getUTCDate(dateUTC) {
	try {
		if (!dateUTC) {
			return dateUTC
		}
		let date = new Date(dateUTC);
		let year = date.getFullYear();
		let month = date.getMonth() + 1;
		let dt = date.getDate();
		if (dt < 10) {
			dt = '0' + dt;
		}
		if (month < 10) {
			month = '0' + month;
		}
		let formatDate = dt + '.' + month + '.' + year;
		return formatDate;
	} catch (error) {
		return dateUTC
	}
  
}

const getLinkChangesText = (changes) => {
	const linkText = getLinkName(changes.link)
	const prevDate = getUTCDate(changes.dateBeforeChange)
	const newDate = getUTCDate(changes.newDate)

	return `${linkText}: ${prevDate} -> ${newDate}`
}  

class History extends React.Component {
	state = {
		collapsed: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			columnDefs: [{
				headerName: "Дата", field: "date"
			}, {
				headerName: "Номер товарной партии", field: "consNum"
			}, {
				headerName: "Статус", field: "statusText"
			}, {
				headerName: "Комментарий", field: "reason"
			},{
				headerName: "Изменения", field: "changes", width: 620
			}],
			defaultColDef: {resizable: true},
		}
	}

	convertStatus(status) {
		switch (status) {
			case 'REQUIRED':
				return 'Ожидает обработки';
			case 'PROCESSING':
				return 'В обработке';
			case 'FAILED':
				return 'Ошибка обработки';
			case 'ACCEPTED':
				return 'Обработано';
			default:
				return 'unknown';
		}
	}

	componentDidMount() {
		privateApi.get(CONSIGNMENTS_HISTORY_URL)
			.then(({data}) => {
			const flatChanges = []

			data.data.forEach(i => {
				for (const change of i.changes) {

					if (change.dateBeforeChange.length > 0) {
						change.dateBeforeChange.forEach(c => {
							flatChanges.push({
								date: getUTCDate(change.date),
								consNum: i.consNum,
								status: change.status,
								statusText: this.convertStatus(change.status),
								reason: change.reason,
								changes: getLinkChangesText(c)
								})
						})
					}

					if (change.status === 'FAILED')  {
						flatChanges.push({
							date: getUTCDate(change.date),
							consNum: i.consNum,
							status: change.status,
							statusText: this.convertStatus(change.status),
							reason: change.reason,
							changes: null
							})
					}

					
				}
			
			  if (i.status === 'PROCESSING') {
				flatChanges.push({
				  date: getUTCDate(new Date()),
				  consNum: i.consNum,
				  status: i.status,
				  statusText: this.convertStatus(i.status),
				  reason: null,
				  changes: null
				})
			  }
			})

			this.setState({rowData: flatChanges})
		})
	}

	render() {

		return (
			<Layout style={{minHeight: '100vh'}}>
				<Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
					<div className="logo"><img src={logo} alt="logo"/></div>
					<Menu theme="dark" defaultSelectedKeys={['3']} mode="inline">
						<Menu.Item key="1"><NavLink to="/consignment">
							<Icon type="unordered-list"/>
							<span>Товарные партии</span></NavLink>
						</Menu.Item>
						<Menu.Item key="2"><NavLink to="/upload">
							<Icon type="download"/>
							<span>Загрузка обновлений</span></NavLink>
						</Menu.Item>
						<Menu.Item key="3"><NavLink to="/history">
							<Icon type="history"/>
							<span>История обновлений</span></NavLink>
						</Menu.Item>
					</Menu>
				</Sider>
				<Layout>
					<Content style={{margin: '0 16px'}}>
						<Row type="flex" justify="space-between">

							<Breadcrumb style={{margin: '16px 0'}}>
								<Breadcrumb.Item>Портал экспедиторов</Breadcrumb.Item>
								<Breadcrumb.Item>История обновлений</Breadcrumb.Item>
							</Breadcrumb>
							<Profile username={user}/>

						</Row>

						<Row type="flex" justify="start">
							<Col xs={20} sm={20} md={20} lg={20} xl={20} style={{padding: 15}}>
								<Title level={3}>Товарные партии</Title>
							</Col>
						</Row>
						<Row type="flex" justify="start">
							<Col xs={24} sm={24} md={24} lg={24} xl={24}>
								<div
									className="ag-theme-material"
									style={{

										width: '100%'
									}}
								>
									<AgGridReact
										columnDefs={this.state.columnDefs}
										rowData={this.state.rowData}
										rowClassRules={{
											"queue": function(params) {
												if (params.data.status === 'PROCESSING') {
													return true
												} else {
													return false
												}
											},
											"accepted": function(params) {
												if (params.data.status === 'ACCEPTED') {
													return true
												} else {
													return false
												}
											},
											"rejected": function(params) {
												if (params.data.status === 'FAILED') {
													return true
												} else {
													return false
												}
											}
										}}
										defaultColDef={this.state.defaultColDef}>
									</AgGridReact>
								</div>

							</Col>

						</Row>
					</Content>
					<Footer style={{textAlign: 'center'}}>Группа компаний IEK © 1999—{yyyy}. Все права
						защищены.</Footer>
				</Layout>
			</Layout>
		);
	}
}

export default History;
