import axios from 'axios';

import AuthClientStore from './authClient'

import { API_URL, REFRESH_TOKEN_URL, CONSIGNMENTS_URL, CONSIGNMENTS_HISTORY_URL, UPLOAD_CONSIGNMENTS_URL } from '../config'


const publicApi = axios.create({
    baseURL: API_URL
})

const privateApi = axios.create({
  baseURL: API_URL
})

privateApi.interceptors.request.use(
    (config) => {
        const token = AuthClientStore.getAccessToken()
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    },
    (error) => Promise.reject(error)
)

privateApi.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
 
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
 
        try {
          const refreshToken = AuthClientStore.getRefreshToken();

          if (refreshToken) {
            const response = await publicApi.post(REFRESH_TOKEN_URL, { refreshToken });
            const { accessToken } = response.data;
    
            AuthClientStore.setAccessToken(accessToken)
    
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;

            let url = CONSIGNMENTS_URL
            let baseURL = originalRequest.baseURL

            if (originalRequest.url.includes(CONSIGNMENTS_HISTORY_URL)) {
              url = CONSIGNMENTS_HISTORY_URL
            }
            if (originalRequest.url.includes(UPLOAD_CONSIGNMENTS_URL)) {
              url = UPLOAD_CONSIGNMENTS_URL
            }

            if ((baseURL.match(/\expd-api/g) || []).length > 1) {
              baseURL = baseURL.replace("/expd-api")
            }

            const req = {...originalRequest, url: url, baseURL}

            return privateApi(req)
          }
        } catch (error) {
          AuthClientStore.removeAccessToken()
          AuthClientStore.removeRefreshToken()
        }
      }
      
      return Promise.reject(error);
    }
  );

export {publicApi, privateApi}