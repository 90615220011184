import React from 'react';
import {Avatar, Icon} from "antd";
import {NavLink, Redirect} from "react-router-dom";

import AuthClientStore from '../../api/authClient'

const logout = async () => {
    try {
        // const refreshToken = localStorage.getItem('refreshToken');
		AuthClientStore.removeRefreshToken()
        AuthClientStore.removeAccessToken()
        // await axios.post(`${API_URL}/logout`, { token: refreshToken });
    } catch (error) {
        console.error('Logout error:', error);
    }
  };

function Profile(props) {
	return (
		<div style={{margin: '16px 0px'}}><Avatar style={{margin: '0px 10px'}} icon={<Icon type="user" />} /><NavLink  to={'/'}><span onClick={() => {

			logout()
			return (
				<Redirect
					to={{
						pathname: '/',
					}}
				/>)
		}
		}>
				Выход
			</span></NavLink></div>
	)
}

export default Profile
