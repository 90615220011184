import React from 'react';
import './App.css';
import 'antd/dist/antd.css'
import {BrowserRouter, Route, Redirect} from "react-router-dom";
import Auth from './components/Auth/AuthV2';
import Register from './components/Register/Register';
import Consignment from './components/Consignment/Consignment';
import Upload from './components/Upload/Upload';
import History from "./components/History/Histoty";
import AuthClientStore from './api/authClient'

function App() {
  return (
      <BrowserRouter>
        <div>
          <Route exact path='/'  render={props => (
               AuthClientStore.getAccessToken() == null ? (
                    <Auth {...props} />
                ) : (
                    <Redirect to={{
                        pathname: '/consignment',
                    }}
                    />
                )
            )} />
          <Route exact path='/registration/' component={Register}/>
            {/* <Route exact path='/consignment/' component={Consignment}/>
			<Route exact path='/upload/' component={Upload}/>*/}
            <Route exact path='/consignment/' render={props => (
               AuthClientStore.getAccessToken() != null ? (
                    <Consignment {...props} />
                ) : (
                    <Redirect to={{
                        pathname: '/',

                    }}
                    />
                )
            )} />
            <Route exact path='/upload/' render={props => (
               AuthClientStore.getAccessToken() != null ? (
                    <Upload {...props} />
                ) : (
                    <Redirect to={{
                        pathname: '/',

                    }}
                    />
                )
            )} />
            <Route exact path='/history/' render={props => (
               AuthClientStore.getAccessToken() != null ? (
                    <History {...props} />
                ) : (
                    <Redirect to={{
                        pathname: '/',

                    }}
                    />
                )
            )} />
        </div>
      </BrowserRouter>
  );
}

export default App;
